import { css } from 'aphrodite/no-important';
import { IAppState } from 'models';

import * as React from 'react';
import { Connect } from 'store/index';
import { style } from './style';

class Footer extends React.Component<IAppState> {
  private styles: any;

  render() {
    const { footer, global } = this.props.stylesData;

    this.styles = style({
      globalStyles: global,
      footerStyles: footer,
      settings: { ...footer.settings },
    });

    return (
      <footer className={css(this.styles.footer)} role='contentinfo'>
        <div className={css(this.styles.container)}>{this._getLinksList()}</div>
      </footer>
    );
  }

  private _getLinksList = () => {
    return Object.keys(this.props.cmsData.text.footer.links)
      .filter((category) => {
        return category.substring(0, 5) === 'link_';
      })
      .map((footerLink, i) => {
        const { copy, link } = this.props.cmsData.text.footer.links[footerLink];

        return (
          link && (
            <a
              href={link}
              className={css(this.styles.footer_link)}
              key={i}
              rel='noopener noreferrer'
              target='_blank'
            >
              {copy}
            </a>
          )
        );
      });
  };
}

export default Connect(Footer);
