import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';

import { darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    button: {
      color: '#808080',
      display: 'inline',
      fontSize: '16px',
      backgroundColor: 'transparent',
      padding: '10px 0 5px',
      textDecoration: 'underline',
      fontWeight: 500,
      ...styles.userStyles.link,

      [media.tablet]: {
        padding: '10px 0',
      },

      ':hover': {
        color: styles.userStyles.link ? darkenColor(styles.userStyles.link.color) : '',
      },

      ':focus': {
        color: styles.userStyles.link ? darkenColor(styles.userStyles.link.color) : '',
      },
    },

    user: {
      width: '100%',
      textAlign: 'right',
      ...styles.globalStyles.container,
      minHeight: '16px',

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
      },
    },

    greeting_message: {
      display: 'inline',
      marginRight: '5px',
      ...styles.userStyles.greetingMessage,
    },
  });
