import Axios, {
  AxiosInstance,
  CreateAxiosDefaults,
  AxiosRequestConfig,
  AxiosError,
} from 'axios';

class HttpError extends Error {
  constructor(message) {
    super(message);
    this.name = 'HttpError';
  }
}

export interface HttpClient {
  post<TRequest, TResponse>(
    path: string,
    object: TRequest,
    config?: AxiosRequestConfig
  ): Promise<TResponse>;
  patch<TRequest, TResponse>(
    path: string,
    object: TRequest
  ): Promise<TResponse>;
  put<TRequest, TResponse>(path: string, object: TRequest): Promise<TResponse>;
  get<TResponse>(path: string): Promise<TResponse>;
}

export class HttpClient implements HttpClient {
  private client: AxiosInstance;

  protected createAxiosClient(config: CreateAxiosDefaults): AxiosInstance {
    return Axios.create({ ...config });
  }

  constructor(config: CreateAxiosDefaults) {
    this.client = this.createAxiosClient(config);
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    config?: AxiosRequestConfig
  ): Promise<TResponse> {
    try {
      const response = await this.client.post<TResponse>(path, payload, config);

      return response.data;
    } catch (error) {
      this.handleServiceError(error);
    }
    return {} as TResponse;
  }

  async patch<TRequest, TResponse>(
    path: string,
    payload: TRequest
  ): Promise<TResponse> {
    try {
      const response = await this.client.patch<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      this.handleServiceError(error);
    }
    return {} as TResponse;
  }

  async put<TRequest, TResponse>(
    path: string,
    payload: TRequest
  ): Promise<TResponse> {
    try {
      const response = await this.client.put<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      this.handleServiceError(error);
    }
    return {} as TResponse;
  }

  async get<TResponse>(path: string): Promise<TResponse> {
    try {
      const response = await this.client.get<TResponse>(path);
      return response.data;
    } catch (error) {
      this.handleServiceError(error);
    }
    return {} as TResponse;
  }

  private handleServiceError(err: unknown) {
    if (err instanceof AxiosError && err.response) {
      // The client was given an error response (5xx, 4xx)
      throw new HttpError(err.response.data);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new HttpError('Unknown error');
    }
  }
}
