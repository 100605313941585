import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';

import { children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    vote: {
      backgroundColor: '#fff',
      margin: 'auto',
      maxWidth: '850px',
      width: '100%',
      paddingBottom: 25,
      ...styles.voteStyles.general,

      [media.tablet]: {
        paddingBottom: 35,
        ...styles.voteStyles.general.tablet,
      },

      [media.desktop]: {
        ...styles.voteStyles.general.desktop,
      },
    },

    media_container: {
      padding: '30px 30px 0',

      [media.tablet]: {
        padding: '0',
      },
    },

    video_wrapper: {
      position: 'relative',
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',

      [children('iframe')]: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
      },
    },

    info_container: {
      padding: '25px 25px 10px',
    },

    cta_container: {
      padding: '10px 25px',
    },

    universalHeadline: {
      fontSize: '18px',
    },

    headline: {
      paddingBottom: '20px',
      ...styles.voteStyles.headline,

      [media.tablet]: {
        ...styles.voteStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.voteStyles.headline.desktop,
      },
    },

    name: {
      paddingBottom: '0px',
      ...styles.voteStyles.name,

      [media.tablet]: {
        ...styles.voteStyles.name.tablet,
      },

      [media.desktop]: {
        ...styles.voteStyles.name.desktop,
      },
    },

    description_1: {
      paddingBottom: '8px',
      ...styles.voteStyles.description_1,

      [media.tablet]: {
        ...styles.voteStyles.description_1.tablet,
      },

      [media.desktop]: {
        ...styles.voteStyles.description_1.desktop,
      },
    },

    description_2: {
      paddingBottom: '10px',
      ...styles.voteStyles.description_2,

      [media.tablet]: {
        ...styles.voteStyles.description_2.tablet,
      },

      [media.desktop]: {
        ...styles.voteStyles.description_2.desktop,
      },
    },

    description_3: {
      paddingBottom: '5px',
      ...styles.voteStyles.description_3,

      [media.tablet]: {
        ...styles.voteStyles.description_3.tablet,
      },

      [media.desktop]: {
        ...styles.voteStyles.description_3.desktop,
      },
    },

    link: {
      marginBottom: '15px',
      ...styles.voteStyles.link,

      [media.tablet]: {
        ...styles.voteStyles.link.tablet,
      },

      [media.desktop]: {
        ...styles.voteStyles.link.desktop,
      },
    },

    vote_buttons_wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: 'auto',
      padding: '10px 0 8px',
      position: 'relative',
    },

    vote_counter: {
      margin: '0 30px',
      paddingBottom: '0',
      ...styles.voteStyles.voteCounter,
    },

    vote_button: {
      height: '44px',
      margin: '0',
      width: '44px',

      [children('svg')]: {
        verticalAlign: 'middle',
      },
    },

    vote_button_plus: {
      ...styles.voteStyles.buttons.increase,

      [children('polygon')]: {
        fill: styles.voteStyles.buttons.increase.fill,
      },

      ':hover:not([aria-disabled="true"])': {
        background: darkenColor(styles.voteStyles.buttons.increase.background),
        borderColor: darkenColor(styles.voteStyles.buttons.increase.background),
        boxShadow: 'none',
        opacity: 0.7,
      },

      ':focus:not([aria-disabled="true"]': {
        background: darkenColor(styles.voteStyles.buttons.increase.background),
        borderColor: darkenColor(styles.voteStyles.buttons.increase.background),
        boxShadow: 'none',
      },
    },

    vote_button_minus: {
      ...styles.voteStyles.buttons.decrease,

      [children('polygon')]: {
        fill: styles.voteStyles.buttons.decrease.fill,
      },

      ':hover:not([aria-disabled="true"])': {
        background: darkenColor(styles.voteStyles.buttons.decrease.background),
        borderColor: darkenColor(styles.voteStyles.buttons.decrease.background),
        boxShadow: 'none',
        opacity: 0.7,
      },

      ':focus:not([aria-disabled="true"]': {
        background: darkenColor(styles.voteStyles.buttons.decrease.background),
        borderColor: darkenColor(styles.voteStyles.buttons.decrease.background),
        boxShadow: 'none',
      },
    },

    nav_arrow: {
      position: 'absolute',
      top: '70px',
      backgroundColor: 'transparent',

      [children('svg')]: {
        width: '20px',
        ...styles.voteStyles.navArrow,
      },

      ':hover svg': {
        fill: darkenColor(styles.voteStyles.navArrow.fill),
      },

      [media.tablet]: {
        top: '50%',
        transform: 'translateY(-50%)',

        [children('svg')]: {
          width: '28px',
          ...styles.voteStyles.navArrow.tablet,
        },

        ':hover svg': {
          fill: styles.voteStyles.navArrow.tablet ? darkenColor(styles.voteStyles.navArrow.tablet.fill) : '',
        },
      },

      [media.desktop]: {
        [children('svg')]: {
          ...styles.voteStyles.navArrow.desktop,
        },

        ':hover svg': {
          fill: styles.voteStyles.navArrow.desktop ? darkenColor(styles.voteStyles.navArrow.desktop.fill) : '',
        },
      },
    },

    nav_prev: {
      left: '5px',

      [media.tablet]: {
        left: '-50px',
      },
    },

    nav_next: {
      right: '5px',

      [media.tablet]: {
        right: '-50px',
      },
    },
  });
