/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EvergentChangeService = {
    responseCode?: EvergentChangeService.responseCode;
    message?: string;
    status?: string;
};

export namespace EvergentChangeService {

    export enum responseCode {
        _1 = '1',
        _0 = '0',
    }


}

