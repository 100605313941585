import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';

import { children, media } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    panel_wrapper: {
      display: 'flex',
      width: `${100 / styles.columns.mobile}%`,

      [media.tablet]: {
        width: `${100 / styles.columns.tablet}%`,
      },

      [media.desktop]: {
        width: `${100 / styles.columns.desktop}%`,
      },
    },

    headline: {
      paddingBottom: '5px',
      lineHeight: '1.2',
      ...styles.globalStyles.headlines,
      ...styles.panelStyles.headline,

      [media.tablet]: {
        paddingBottom: '2px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.panelStyles.headline.tablet,
      },

      [media.desktop]: {
        paddingBottom: '2px',
        ...styles.globalStyles.headlines.desktop,
        ...styles.panelStyles.headline.desktop,
      },
    },

    description_1: {
      width: '100%',
      ...styles.panelStyles.description_1,

      [media.tablet]: {
        ...styles.panelStyles.description_1.tablet,
      },

      [media.desktop]: {
        ...styles.panelStyles.description_1.desktop,
      },
    },

    description_2: {
      width: '100%',
      ...styles.panelStyles.description_2,

      [media.tablet]: {
        ...styles.panelStyles.description_2.tablet,
      },

      [media.desktop]: {
        ...styles.panelStyles.description_2.desktop,
      },
    },

    description_3: {
      width: '100%',
      ...styles.panelStyles.description_3,

      [media.tablet]: {
        ...styles.panelStyles.description_3.tablet,
      },

      [media.desktop]: {
        ...styles.panelStyles.description_3.desktop,
      },
    },

    votes: {
      ...styles.panelStyles.votes,
    },

    vote_wrapper: {
      marginTop: 'auto',
    },

    panel: {
      display: 'flex',
      backgroundColor: '#fff',
      flexDirection: 'column',
      margin: '15px',
      width: '100%',
      ...styles.panelStyles.default,

      [children('img')]: {
        margin: '0 auto',
        width: '100%',
        flexShrink: 0,
      },
    },

    text_wrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '15px',
      textAlign: 'center',
      width: '100%',
    },

    ad_wrapper: {
      flexBasis: '300px',
      flexGrow: 1,
    },
  });
