/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EvergentResumeSubscription = {
    responseCode?: EvergentResumeSubscription.responseCode;
    message?: string;
    status?: string;
};

export namespace EvergentResumeSubscription {

    export enum responseCode {
        _1 = '1',
        _0 = '0',
    }


}

