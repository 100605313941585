/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EvergentCancelPause = {
    responseCode?: EvergentCancelPause.responseCode;
    message?: string;
    status?: string;
};

export namespace EvergentCancelPause {

    export enum responseCode {
        _1 = '1',
        _0 = '0',
    }


}

