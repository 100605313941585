import BASE64 from 'crypto-js/enc-base64';
import HMACSHA256 from 'crypto-js/hmac-sha256';

import { IDeferred, IGenericObject } from 'models';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';

export default class Util {
  /**
   * Deferred - create a deferred object to
   */
  public static Deferred(): IDeferred {
    let res;
    let rej;

    const def = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as IDeferred;

    if (res && rej) {
      def.resolve = res;
      def.reject = rej;
    }

    return def;
  }
}

export const fixedEncodeUriComponent = (str: string) => {
  return encodeURIComponent(str)
    .replace(/[!'()]/g, escape)
    .replace(/\*/g, '%2A');
};

export const convertToQspString = (params: any) => {
  let result = '';
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (value) {
        const encodedValue = fixedEncodeUriComponent(String(value));
        const encodedKey = fixedEncodeUriComponent(String(key));
        result += `${encodedKey}=${encodedValue}&`;
      }
    }
  }
  return result.slice(0, -1);
};

export const createHash = (qsp: string, secret: string) => {
  const hash = HMACSHA256(qsp, secret);
  return BASE64.stringify(hash);
};

export const normalizeForUrl = (name: string) => {
  return name.replace(/ /g, '_').replace(constants.URL_REGEX, '');
};

const toCamel = (s: any) => {
  return s.replace(/([-_][a-z])/gi, ($1: any) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const normalizeStyles = function (o: any) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      if (k === 'background_image') {
        o[k] = o[k] && `url(${o[k]})`;
      }
      n[toCamel(k)] = normalizeStyles(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return normalizeStyles(i);
    });
  }

  return o;
};

export const checkIfTrue = (value: string) => {
  return ['true', '1', true, 1, 'yes'].indexOf(value) >= 0;
};

export const shuffleArr = (arr: []) => {
  const shuffledArr = [...arr];
  let currentIndex = shuffledArr.length;
  let temporaryValue;
  let randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = shuffledArr[currentIndex];
    shuffledArr[currentIndex] = shuffledArr[randomIndex];
    // @ts-ignore
    shuffledArr[randomIndex] = temporaryValue;
  }

  return shuffledArr;
};

export const sortArrByProp = (arr: [], field: string, dir: string) => {
  return [...arr].sort((a: IGenericObject, b: IGenericObject) => {
    if (dir === 'ascending') {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    } else if (dir === 'descending') {
      if (a[field] < b[field]) {
        return 1;
      }
      if (a[field] > b[field]) {
        return -1;
      }
      return 0;
    }
    return 0;
  });
};

export const twitterShare = (copy: string, tracking: string, link?: string) => {
  const linkOut = link ? `&url=${link}` : '';

  googleHelpers.trackGoogleEvent(
    constants.GA_CATEGORIES.BUTTON_CLICK,
    tracking,
    ''
  );
  const url = `${constants.TWITTER_INTENT_URL}${encodeURIComponent(
    copy
  )}${linkOut}`;
  window.open(url, '_blank', 'height=420,width=550');
};

export const insertFonts = (urls: string[]) => {
  const unloadedUrls = urls.filter(
    (url) => !document.querySelectorAll(`link[href='${url}']`).length
  );

  unloadedUrls.forEach((url) => {
    if (url) {
      const linkEl = document.createElement('link');
      linkEl.rel = 'stylesheet';
      linkEl.href = url;
      document.head.appendChild(linkEl);
    }
  });
};

/**
 * insertAt
 * Inserts an item into a list at a given index
 * @param list list to have item inserted into
 * @param item item to be inserted into list
 * @param i index to insert item at
 */
export const insertAt = (list: any[], item: any, i: number) => {
  const updatedList = [...list];

  if (!item) {
    return updatedList;
  }

  updatedList.splice(i, 0, item);

  return updatedList;
};

export const getDeviceData = () => {
  if (/Android/i.test(navigator.userAgent)) {
    return 'android';
  }
  if (/iPhone/i.test(navigator.userAgent)) {
    return 'iphone';
  }
  if (/iPad/i.test(navigator.userAgent)) {
    return 'ipad';
  }
  if (
    /Mobile|webOS|iPod|Kindle|BlackBerry|PalmOS|PalmSource|Opera Mini|IEMobile|SonyEricsson|smartphone/i.test(
      navigator.userAgent
    )
  ) {
    return 'unknown';
  }
  return 'pc';
};

/**
 * Pluralizes a string if the count is not equal to 1. Only works for strings in which the plural form ends in 's'.
 * Preserves the case of the string.
 * This is a specific use case. This function is not intended to be a generalized pluralizer
 * @example
 * pluralize(10, vote) // votes
 * pluralize(1, VOTE) // VOTE
 * pluralize(2, Vote, true) // 2 Votes
 * @param count number of things
 * @param str string to pluralize if count is not 1
 * @param inclusive whether to prefix the str with the count
 * @returns pluralized form of string if it should be pluralized, preserving case.
 */
export const pluralizeS = (count: number, str: string, inclusive = false) => {
  const isUpperCase = str.substr(-1).toUpperCase() === str.substr(-1);
  const plural = isUpperCase ? `${str}S` : `${str}s`;

  if (count === 1) {
    return inclusive ? `${count} ${str}` : str;
  }

  return inclusive ? `${count} ${plural}` : `${plural}`;
};

export function isWebview() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);
  const wv = /\bwv\b/.test(userAgent);
  if (ios) {
    return !safari;
  } else {
    return wv;
  };
}