import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    player_wrapper: {
      position: 'relative',
      paddingTop: '56.25%',
    },
    react_player: {
      position: 'absolute',
      top: '0',
      left: '0',
    },
  });
