import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';
import { children } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    form: {
      margin: '20px 0',
    },

    form_input: {
      borderBottom: '1px solid #979797',
      borderColor: '#979797',
      fontSize: '16px',
      height: '36px',
      padding: '0 10px',
      width: '100%',
    },

    form_select: {
      ':hover': {
        backgroundColor: '#e5e7eb',
      },
      cursor: 'pointer',
    },

    form_select_wrapper: {
      position: 'relative',
    },

    form_input_invalid: {
      borderColor: '#D0021B',
    },

    form_error: {
      color: '#D0021B',
      fontSize: '13px',
      margin: '10px 0',
      textAlign: 'left',
    },

    form_hint: {
      color: '#4e5356',
      fontSize: '12px',
      margin: '10px 0',
      textAlign: 'left',
    },

    form_group: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 36,
    },

    form_label: {
      fontSize: '12px',
    },

    select_icon_wrapper: {
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      marginRight: '0.25rem',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '0.25rem',
      paddingRight: '0.25rem',
      color: 'currentColor',

      [children('> svg')]: {
        transform: 'rotate(-90deg)',
      },
    },
  });
