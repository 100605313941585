import { isWebview } from '../util';

export const PageName = {
  VOTE: 'nba:ist-mvp-vote:main',
  CLOSED: 'nba:ist-mvp-vote:closed',
  GEO: 'nba:ist-mvp-vote:not-available'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type PageName = typeof PageName[keyof typeof PageName];

export const pageView = (name: PageName, {language, userID = ""} : {language?: string, userID?: string | undefined}) => {
  window.digitalData = {
    page: {
      pageInfo: {
        pageName: PageName[name],
        siteSection: "nba:ist-mvp-vote"
      },
    },
    site: {
      league: "nba",
      partner: "telescope",
      propertyName: isWebview()? "nba:app": "nba:web", // "nba:web"; or nba:app if presented in webview
    },
    user: {
      ...(language && {language}),
      userState: userID? "authenticated" : "guest",
      // AUTHENTICATION & AUTHORIZATION
      userID: userID, // NBA CIAM GUID, if authenticated
    },
  };
}

export const ModalName = {
  VOTE: 'nba:ist-mvp-vote:player',
  THANKS: 'nba:ist-mvp-vote:vote-confirmation',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type ModalName = typeof ModalName[keyof typeof ModalName];

export const modalView = (name: ModalName, content: {playerName: string, playerID: string, teamName: string, teamID: string}) => {
  const {playerName, playerID, teamName, teamID} = content;

  let modalDetail = {
    page: {
      pageInfo: {
        pageName: ModalName[name],
      },
      content: {
        playerName, // proper-case player name
        playerID, // player ID number
        teamName, // team tricode
        teamID // team ID number
      }
    }
  }

  window._satellite && window._satellite.track("track-modal", modalDetail);
}

export const InteractionType = {
  VOTE: 'nba:ist-mvp-vote:player:vote',
  VOTE_DISMISS: 'nba:ist-mvp-vote:player:dismiss',
  LOGIN_DISMISS: 'nba:ist-mvp-vote:login:dismiss',
  SHARE: 'nba:ist-mvp-vote:vote-confirmation:share',
  THANKS_DISMISS: 'nba:ist-mvp-vote:vote-confirmation:dismiss',
} as const;


// eslint-disable-next-line -- intentionally naming the object the same as the type
export type InteractionType = typeof InteractionType[keyof typeof InteractionType];

export const trackInteraction = (type: InteractionType, content?: {playerName: string, playerID: string, teamName: string, teamID: string}) => {
  const {playerName, playerID, teamName, teamID} = content || {};
  
  let interactionDetail = {
    interactionId: InteractionType[type],
    playerName, // proper-case player name
    playerID, // player ID number
    teamName, // team tricode
    teamID // team ID number
  }

  window._satellite && window._satellite.track("track-click", interactionDetail);
}

export const CiamEventName = {
  LOGIN: 'login',
  REGISTRATION: 'registration'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type CiamEventName = typeof CiamEventName[keyof typeof CiamEventName];

export const CiamEventDesc = {
  START: 'start',
  SUBMIT: 'submit',
  SUCCESS: 'success',
  ERROR: 'error'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type CiamEventDesc = typeof CiamEventDesc[keyof typeof CiamEventDesc];

type CiamEvent = {
  eventName: CiamEventName,
  eventDesc: CiamEventDesc,
  eventError?: string[], // "<error field 1>|<error message 1>"
  language: string,
  userId?: string,
  usePersonalInfo?: boolean
}

export const trackCiamEvent = ({ eventName, eventDesc, eventError = [], language, userId, usePersonalInfo } : CiamEvent) => {
  console.log('trackCiamEvent', { eventName, eventDesc, eventError, language, userId, usePersonalInfo })
  let ciamEventDetail = {
    eventName,
    eventDesc,
    eventError, // comma-separated list of errors; each error gives a field or type
      // followed by a pipe and then a specific error description
    detailInfo: {
      language,
      ...(userId && {userId}), // Only upon registration success & login success events
      userState: userId? "authenticated" : "guest", // must be "authenticated" upon success
      ...(usePersonalInfo && {usePersonalInfo}) // if user opts in to marketing communications
    }
   }

  window._satellite && window._satellite.track("ciamEvent", ciamEventDetail);
}