import { IAppState } from 'models';
import { AppStateProvider } from 'store/index';

export function closeModal(this: AppStateProvider): void {
  const { modalProps } = this.state;
  modalProps.type = '';

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      modalProps,
    };
  });
}

export function openModal(this: AppStateProvider, type: string, onClose?: () => void): void {
  const { modalProps } = this.state;
  modalProps.type = type;
  modalProps.onClose = onClose;

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      modalProps,
    };
  });
}

export function onClose(this: AppStateProvider, event: () => void): void {
  const { modalProps } = this.state;
  modalProps.onClose = event;

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      modalProps,
    };
  });
}