import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';
import { children, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    login: {
      display: 'flex',
      flexDirection: 'column',
      padding: '25px 45px',
    },

    headline: {
      textAlign: 'left',
      paddingBottom: '15px',
      ...styles.globalStyles.headlines,
      ...styles.loginStyles.headline,

      [media.tablet]: {
        ...styles.globalStyles.headlines.tablet,
        ...styles.loginStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.loginStyles.headline.desktop,
      },
    },

    button: {
      fontSize: '16px',
      marginTop: 35,
      opacity: styles.areButtonsEnabled ? 1 : 0.2,
      padding: '8px',
      pointerEvents: styles.areButtonsEnabled ? 'auto' : 'none',
      width: '100%',
      minHeight: '40px',
      fontWeight: 700,
    },

    email_button: {
      ...styles.buttonStyles.email,

      ':hover': {
        background: darkenColor(styles.buttonStyles.email.background),
      },

      ':focus': {
        background: darkenColor(styles.buttonStyles.email.background),
      },

      [media.tablet]: {
        ...styles.buttonStyles.email.tablet,

        ':hover': {
          background: styles.buttonStyles.email.tablet ? darkenColor(styles.buttonStyles.email.tablet.background) : '',
        },

        ':focus': {
          background: styles.buttonStyles.email.tablet ? darkenColor(styles.buttonStyles.email.tablet.background) : '',
        },
      },

      [media.desktop]: {
        ...styles.buttonStyles.email.desktop,

        ':hover': {
          background: styles.buttonStyles.email.desktop
            ? darkenColor(styles.buttonStyles.email.desktop.background)
            : '',
        },

        ':focus': {
          background: styles.buttonStyles.email.desktop
            ? darkenColor(styles.buttonStyles.email.desktop.background)
            : '',
        },
      },
    },

    facebook_button: {
      lineHeight: '1',
      ...styles.buttonStyles.facebook,

      [children('svg')]: {
        fill: '#fff',
        height: '20px',
        transform: 'translateY(1px)',
      },

      [children('> span')]: {
        display: 'inline-block',
        marginRight: '15px',
        verticalAlign: 'text-top',
      },

      ':hover': {
        background: darkenColor(styles.buttonStyles.facebook.background),
      },

      ':focus': {
        background: darkenColor(styles.buttonStyles.facebook.background),
      },
    },

    divider: {
      display: 'flex',
      alignItems: 'center',
      margin: '20px 0 24px',
    },

    divider_line: {
      flexGrow: 1,
      height: '1px',
      backgroundColor: '#ccc',
    },

    divider_text: {
      padding: '0 15px',
      fontWeight: 'bold',
      fontSize: '16px',
    },

    form_input: {
      borderBottom: '1px solid #979797',
      borderColor: '#979797',
      fontSize: '16px',
      height: '36px',
      padding: '0 10px',
      width: '100%',
    },

    form_input_invalid: {
      borderColor: styles.loginStyles.error.color,
    },

    form_error: {
      color: '#D0021B',
      fontSize: '13px',
      margin: '10px 0',
      textAlign: 'left',
      ...styles.loginStyles.error,
    },

    form_group: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 36,
    },

    form_group_inline: {
      display: 'flex',
      flexDirection: 'row',
      [children('> *')]: {
        flexGrow: 1,
      },
      [children(' > * + *')]: {
        marginLeft: 15,
      },
    },

    form_label: {
      ...styles.loginStyles.emailCta,
      fontSize: '12px',
    },

    form: {
      margin: '20px 0',
    },

    not_registered_link: {
      fontSize: '14px',
      textDecoration: 'none',

      [children('u')]: {
        textDecoration: 'underline',
      },
    },

    already_registered_link: {
      fontSize: '14px',
      textDecoration: 'underline',
      margin: 0,
    },

    footer: {
      color: '#727272',
      fontSize: '12px',
      textAlign: 'center',

      [children('a')]: {
        color: 'inherit',
      },
    },

    subheadline: {
      fontSize: '14px',
    },

    logo: {
      height: '45px',
      marginBottom: '7px'
    }
  });
