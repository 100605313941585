export const breakpoints = {
  mobile: 320,
  tablet: 750,
  desktop: 980,
};

export const media = {
  mobile: `@media (min-width: ${breakpoints.mobile}px)`,
  tablet: `@media (min-width: ${breakpoints.tablet}px)`,
  desktop: `@media (min-width: ${breakpoints.desktop}px)`,
};

export const children = (child: string) => {
  return `:nth-child(1n) ${child}`;
};

export const appMaxWidth = '970px';
export const rootFontSize = '16px';

/* tslint:disable:no-bitwise */
export const darkenColor = (color: string) => {
  let hex = '';

  switch (color) {
    case undefined:
    case null:
    case '#000':
    case '#000000':
      break;
    case '#fff':
    case '#ffffff':
      hex = '#ccc';
      break;
    default:
      const percent = -0.2;
      const f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = (f >> 8) & 0x00ff,
        B = f & 0x0000ff;
      hex =
        '#' +
        (
          0x1000000 +
          (Math.round((t - R) * p) + R) * 0x10000 +
          (Math.round((t - G) * p) + G) * 0x100 +
          (Math.round((t - B) * p) + B)
        )
          .toString(16)
          .slice(1);
      break;
  }

  return hex;
};
/* tslint:enable:no-bitwise */

export const getCurrentBreakpoint = (): string => {
  let currentBreakpoint = 'mobile';

  for (const key in breakpoints) {
    if (window.innerWidth >= breakpoints[key]) {
      currentBreakpoint = key;
    }
  }

  return currentBreakpoint;
};

export const pxToRem = (fontSize: string) => {
  return `${parseInt(fontSize, 10) / parseInt(rootFontSize, 10)}rem`;
};
