import { IAppState, IGenericObject } from 'models';
import * as React from 'react';
import * as api from 'store/api';
import * as auth from 'store/auth';
import * as cms from 'store/cms';
import * as global from 'store/global';
import * as grid from 'store/grid';
import * as loading from 'store/loading';
import * as login from 'store/login';
import * as modal from 'store/modal';
import * as terms from 'store/terms';
import * as vote from 'store/vote';
import * as constants from 'util/constants';

const { Consumer, Provider } = React.createContext({} as IAppState);

class AppStateProvider extends React.Component<React.PropsWithChildren> {
  public state: IAppState = {
    globalProps: {
      contestants: [],
      contestantIndex: 0,
      device: '',
      language: {
        ietf: 'EN-US',
        code: 'en'
      },
      userData: {
        country: '',
        email: '',
        facebookId: '',
        isAuthorized: false,
        method: '',
        name: '',
        state: '',
        userId: '',
      },
    },

    globalFn: {
      setDevice: global.setDevice.bind(this),
      setTargetIndex: global.setTargetIndex.bind(this),
      detectLanguage: global.detectLanguage.bind(this),
    },

    apiFn: {
      generatePayload: api.generatePayload.bind(this),
    },

    authFn: {
      authorizeUser: auth.authorizeUser.bind(this),
      login: auth.login.bind(this),
      logout: auth.logout.bind(this),
      setAuthType: auth.setAuthType.bind(this),
    },

    authProps: {
      authType: constants.AUTH_TYPES.LOGIN,
    },

    cmsData: {},

    cmsFn: {
      handleGeoData: cms.handleGeoData.bind(this),
      storeCmsData: cms.storeCmsData.bind(this),
      storeStyles: cms.storeStyles.bind(this),
    },

    cmsProps: {
      inRegion: true,
      isAppReady: false,
    },

    loadingFn: {
      setDescription: loading.setDescription.bind(this),
      setTitle: loading.setTitle.bind(this),
    },

    loadingProps: {
      description: '',
      title: '',
      timeout: 0,
    },

    loginFn: {
      updateRetryFb: login.updateRetryFb.bind(this),
    },

    loginProps: {
      shouldRetryFb: false,
    },

    match: {},

    modalFn: {
      closeModal: modal.closeModal.bind(this),
      openModal: modal.openModal.bind(this),
      onClose: modal.onClose.bind(this)
    },

    modalProps: {
      type: '',
    },

    gridFn: {
      toggleSort: grid.toggleSort.bind(this),
      createContestantList: grid.createContestantList.bind(this),
    },

    gridProps: {
      sortingMethod: constants.ALPHABETICAL,
      sortingDirection: constants.ASCENDING,
    },

    termsFn: {
      updateTerms: terms.updateTerms.bind(this),
    },

    termsProps: {},

    stylesData: {},

    voteFn: {
      getContestantVotes: vote.getContestantVotes.bind(this),
      updateVotes: vote.updateVotes.bind(this),
      submitVote: vote.submitVote.bind(this),
    },

    voteProps: {
      contestantVotes: {},
      totalVotes: 0,
    },
  };

  public render(): React.ReactNode {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const Connect = (Component: typeof React.Component | ((props: any) => any)) => {
  return (props: IGenericObject) => {
    return <Consumer>{(data) => <Component {...data} {...props} />}</Consumer>;
  };
};

export { Connect, AppStateProvider };
