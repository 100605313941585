import { css } from 'aphrodite/no-important';
import { SelectField, TextField } from 'components/form';
import { COUNTRIES, MONTHS, YEARS } from 'components/form/constants';
import Terms from 'components/terms';
import { ApiError, AuthenticationService } from 'lib/nba-ciam-api';
import { IAppState } from 'models';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import ClipLoader from 'react-spinners/ClipLoader';
import { Connect } from 'store/index';
import * as constants from 'util/constants';
import * as googleHelpers from 'util/google-helpers';
import { checkIfTrue } from 'util/helpers';
import { storageFactory } from 'util/storage_helpers';
import { style } from '../login/style';
import parse from 'html-react-parser';
import * as adobeTagManager from 'util/adobe-tag-manager';

const optionsMap = {
  months: MONTHS,
  years: YEARS,
  countries: COUNTRIES,
};
const localStore = storageFactory(localStorage);

type RegisterProps = IAppState;

const Register = (props: RegisterProps) => {
  const lang = props.globalProps.language.code;

  const { copy: copyData, fields: registrationFields, logo } = props.cmsData.text.register;
  const copy = copyData[lang];

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const styles = style({
    globalStyles: props.stylesData.global,
    loginStyles: props.stylesData.login,
    buttonStyles: props.stylesData.login.buttons,
    areButtonsEnabled: true,
  });

  React.useEffect(() => {
    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName.REGISTRATION, 
      eventDesc: adobeTagManager.CiamEventDesc.START, 
      language: props.globalProps.language.ietf
    })
  }, [])

  const areOptinsValid = () => {
    return Object.keys(props.termsProps).every((term) => {
      return (
        (props.termsProps[term].isChecked && props.termsProps[term].isRequired) || !props.termsProps[term].isRequired
      );
    });
  };

  const handleRegister = async (data: any) => {
    setIsSubmitting(true);
    const { email: emailAddress, password, dateOfBirthMonth, dateOfBirthYear, firstName, lastName, country } = data;
    const { marketing, terms } = props.termsProps;
    const payload = {
      emailAddress,
      password,
      firstName,
      lastName,
      dateOfBirth: `${dateOfBirthYear}-${dateOfBirthMonth}-01`,
      country,
      privacyConsent: terms.isChecked ? '1' : '0',
      privacyConsentTimestamp: new Date().getTime().toString(),
      emailConsent: marketing.isChecked ? '1' : '0',
      channel: 'NBAdotcom',
      leagueTeam: 'League',
      league: 'NBA',
      customSourceDetails: {
        registerReason: "NBAISTMVP|23-24|NBA|League|ISTMVPVOTE|Registration",
        registerReasonCode: "NBAISTMVP|23-24|NBA|League|ISTMVPVOTE|Registration",
        registerSource: "TelescopeVoting"
      }
    }

    trackLoginSubmit();

    try {
      const response = await AuthenticationService.postRegister('web', payload);
      const userId = response.data?.user.alternateIds?.NBACIAMGUID!;

      const userPayload = JSON.stringify({
        [emailAddress]: { ...props.termsProps, userId },
      });
      localStore.setItem(constants.EMAIL_LOCALSTORAGE_LABEL, userPayload);
      props.authFn.login({ email: emailAddress, userId });

      trackLoginSuccess();
      
    } catch (err) {
      let message = 'An error occurred. Please try again.';

      const isNbaError = err instanceof ApiError;
      if (isNbaError&& err.body.data.details.length > 0) {
        const { details } = err.body.data;
        message = details[0].description;
      }

      trackLoginError(isNbaError? err.body.data.details : message);
      setError('root.serverError', { message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const trackLoginSubmit = () => {
    googleHelpers.trackGoogleEvent(constants.GA_CATEGORIES.BUTTON_CLICK, constants.GA_EVENTS.EMAIL_LOGIN, '');

    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName.LOGIN, 
      eventDesc: adobeTagManager.CiamEventDesc.SUBMIT, 
      language: props.globalProps.language.ietf
    })
  }

  const trackLoginSuccess = () => {
    const user = props.globalProps.userData;
    const terms = props.termsProps;

    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName.REGISTRATION, 
      eventDesc: adobeTagManager.CiamEventDesc.SUCCESS, 
      language: props.globalProps.language.ietf,
      userId: user.userId,
      usePersonalInfo: terms.marketing.isChecked
    })
  }

  const trackLoginError = (error: {description: string, errorCode: string}[] | string) => {
    const isString = typeof error === 'string';
    const eventError = isString? [`FORM|${error}`] : error.map((err) => `${err.errorCode}|${err.description}`);

    adobeTagManager.trackCiamEvent({ 
      eventName: adobeTagManager.CiamEventName.LOGIN, 
      eventDesc: adobeTagManager.CiamEventDesc.SUCCESS, 
      eventError,
      language: props.globalProps.language.ietf
    })
  }

  const renderFields = () => {
    const fields = Object.keys(registrationFields).map((field, index) => {
      const fieldData = registrationFields[field];

      const hasError = errors[field];
      const isRequired = checkIfTrue(fieldData.required);
      const copy = fieldData.copy[lang];

      switch (fieldData.type) {
        case 'email':
        case 'password':
        case 'text':
          return (
            <TextField
              key={index}
              label={copy.label}
              type={fieldData.type}
              {...register(field, {
                required: { value: isRequired, message: copy.error },
                setValueAs: (value) => {
                  const trimmed = value.trim();
                  return trimmed.length > 0 ? trimmed : undefined;
                },
              })}
              hint={copy.hint}
              isInvalid={!!hasError}
              errors={errors}
            />
          );
        case 'select':
          return (
            <SelectField
              key={index}
              label={copy.label}
              {...register(field, { required: { value: isRequired, message: copy.error } })}
              options={optionsMap[fieldData.options]}
              isInvalid={!!hasError}
              errors={errors}
              hint={copy.hint}
            />
          );
        case 'dob':
          return (
            <div key={index} className={css(styles.form_group_inline)}>
              <SelectField
                label={copy.label_month}
                {...register('dateOfBirthMonth', { required: { value: isRequired, message: copy.error } })}
                options={optionsMap.months}
                isInvalid={!!hasError}
                errors={errors}
                hint={copy.hint}
              />
              <SelectField
                label={copy.label_year}
                {...register('dateOfBirthYear', { required: { value: isRequired, message: copy.error } })}
                options={optionsMap.years}
                isInvalid={!!hasError}
                errors={errors}
                hint={copy.hint}
              />
            </div>
          );
      }
    });

    return fields;
  };

  return (
    <div aria-label='Log in' className={css(styles.login)}>
      <div className={css(styles.logo)}>
        <img src={logo.src} alt={logo.alt_text} />
      </div>

      <h2 className={css(styles.headline)}>{copy.headline}</h2>

      {copy.subheadline && <p className={css(styles.subheadline)}>{parse(copy.subheadline)}</p>}
      <div>
        <button
          className={css(styles.already_registered_link)}
          onClick={() => props.authFn.setAuthType(constants.AUTH_TYPES.LOGIN)}
        >
          {copy.already_registered_link}
        </button>
      </div>
      <form className={css(styles.form)} onSubmit={handleSubmit(handleRegister)}>
        {renderFields()}
        <Terms />
        <button
          className={css(styles.button, styles.email_button)}
          disabled={isSubmitting || !areOptinsValid()}
          type='submit'
        >
          {isSubmitting ? <ClipLoader loading size={20} color={'#fff'} /> : copy.register_button}
        </button>
        {errors.root?.serverError && <p className={css(styles.form_error)}>{errors.root.serverError.message}</p>}
      </form>

      {copy.footer && <p className={css(styles.footer)} dangerouslySetInnerHTML={{ __html: copy.footer }}></p>}
    </div>
  );
};

export default Connect(Register);