import * as ReactGA from 'react-ga';
import * as constants from 'util/constants';

let isGoogleInitialized = false;

export const initializeGoogleAnalytics = (gaId: string) => {
  if (gaId && !isGoogleInitialized) {
    ReactGA.initialize(gaId);
    isGoogleInitialized = true;
  }
};

export const trackGoogleEvent = (
  category: string,
  action: string,
  label: string
) => {
  if (isGoogleInitialized) {
    ReactGA.event({ category, action, label });
  }
};

export const trackGooglePage = (page: string) => {
  if (isGoogleInitialized) {
    ReactGA.pageview(page);
  }
};

export const checkLinkClick = (evt: any) => {
  if (evt.target.tagName.toLowerCase() === 'a') {
    const category = constants.GA_CATEGORIES.LINK_CLICK;
    const action = evt.target.textContent + evt.target.href;
    const label = evt.target.textContent + evt.target.href;
    trackGoogleEvent(category, action, label);
  }
};

export const addLinkClickListener = () => {
  document.addEventListener('click', checkLinkClick, false);
};

export const removeLinkClickListener = () => {
  document.removeEventListener('click', checkLinkClick, false);
};
