import { css } from 'aphrodite/no-important';
import { IAppState } from 'models';

import * as React from 'react';
import { Connect } from 'store/index';
import { DAY, HOUR, MINUTE, SECOND } from 'util/constants';
import * as helpers from 'util/helpers';
import CountdownUnit from './countdown-unit';
import { style } from './style';

interface ITime {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

class Countdown extends React.Component<IAppState> {
  public state = {
    ariaAlertMessage: '',
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
    displayDays: false,
    displayHours: false,
    displayMinutes: false,
    displaySeconds: false,
  };

  public timer: number | undefined;

  public render() {
    const { copy, settings } = this.props.cmsData.text.countdown_clock;
    const {
      days,
      hours,
      minutes,
      seconds,
      displayDays,
      displayHours,
      displayMinutes,
      displaySeconds,
    } = this.state;
    const styles = style({
      globalStyles: this.props.stylesData.global,
      countdownStyles: this.props.stylesData.countdownClock,
    });

    const colonSeparator = (
      <span className={css(styles.colon)} aria-hidden='true'>
        {' '}
        :{' '}
      </span>
    );

    return (
      <section className={css(styles.countdown_container)}>
        <h2 className={css(styles.description)}> {copy.description} </h2>

        <div className={css(styles.timer_wrapper)} role='timer'>
          {displayDays && (
            <CountdownUnit
              styles={styles}
              number={days}
              displayLabel={settings.display.labels}
              labels={copy.labels.days}
            />
          )}

          {displayDays && displayHours && colonSeparator}

          {displayHours && (
            <CountdownUnit
              styles={styles}
              number={hours}
              displayLabel={settings.display.labels}
              labels={copy.labels.hours}
            />
          )}

          {displayHours && displayMinutes && colonSeparator}

          {displayMinutes && (
            <CountdownUnit
              styles={styles}
              number={minutes}
              displayLabel={settings.display.labels}
              labels={copy.labels.minutes}
            />
          )}

          {displayMinutes && displaySeconds && colonSeparator}

          {displaySeconds && (
            <CountdownUnit
              styles={styles}
              number={seconds}
              displayLabel={settings.display.labels}
              labels={copy.labels.seconds}
            />
          )}
        </div>
        <p
          className='screen-reader-text'
          role='alert'
          aria-live='assertive'
          aria-atomic='true'
        >
          {this.state.ariaAlertMessage}
        </p>
      </section>
    );
  }

  public componentWillMount() {
    const { settings } = this.props.cmsData.text.countdown_clock;
    const displayUnits = {
      displayDays: helpers.checkIfTrue(settings.display.days),
      displayHours: helpers.checkIfTrue(settings.display.hours),
      displayMinutes: helpers.checkIfTrue(settings.display.minutes),
      displaySeconds: helpers.checkIfTrue(settings.display.seconds),
    };

    this.setState({ ...displayUnits });
  }

  public componentDidMount() {
    this.updateCountdown();
  }

  public componentWillUnmount() {
    window.clearTimeout(this.timer);
  }

  private updateCountdown() {
    const { copy } = this.props.cmsData.text.countdown_clock;

    const endDate = new Date(
      `${copy.month_name} ${copy.day_number} ${copy.year_number} ${copy.hours_number}:${copy.minutes_number}:${copy.seconds_number} ${copy.meridiem} ${copy.timezone} `
    );
    const currentDate = new Date();
    const timeDiff = (endDate.getTime() - currentDate.getTime()) / 1000;

    if (isNaN(timeDiff) || timeDiff <= 0) {
      return;
    }

    const time = this.convertTimeToCountdown(timeDiff);
    const ariaAlertMessage = this.shouldUpdateAriaAlertMessage(time)
      ? this.getAriaAlertMessage(time)
      : this.state.ariaAlertMessage;

    this.setState({ ...time, ariaAlertMessage });
    this.timer = window.setTimeout(this.updateCountdown.bind(this), 1000);
  }

  private convertTimeToCountdown(remaining: any): ITime {
    let { days, hours, minutes, seconds } = this.state;
    const { displayDays, displayHours, displayMinutes, displaySeconds } =
      this.state;

    if (displayDays) {
      days = this.formatTimeValue(Math.floor(remaining / DAY));

      remaining %= DAY;
    }

    if (displayHours) {
      hours = this.formatTimeValue(Math.floor(remaining / HOUR));

      remaining %= HOUR;
    }

    if (displayMinutes) {
      minutes = this.formatTimeValue(Math.floor(remaining / MINUTE));

      remaining %= MINUTE;
    }

    if (displaySeconds) {
      seconds = this.formatTimeValue(Math.floor(remaining / SECOND));
    }

    return { days, hours, minutes, seconds };
  }

  private formatTimeValue(val: number): string {
    const str = val.toString();
    const shouldModify = str.length < 2;

    return shouldModify ? `00${str}`.slice(-2) : str;
  }

  /**
   * getTotalSecondsRemaining()
   * returns the total number of seconds remaining on the countdown
   * @returns total seconds remaining
   */
  private getTotalSecondsRemaining(): number {
    const { days, hours, minutes, seconds } = this.state;
    const numDays = parseInt(days, 10) * DAY;
    const numHours = parseInt(hours, 10) * HOUR;
    const numMinutes = parseInt(minutes, 10) * MINUTE;
    const numSeconds = parseInt(seconds, 10);

    return numDays + numHours + numMinutes + numSeconds;
  }

  /**
   * getAriaAlertMessage()
   * returns an alert message so that the timer is announced to users with assistive devices in an understandable way
   * @param time ITime
   * @returns alert message
   */
  private getAriaAlertMessage(time: ITime): string {
    const { days, hours, minutes, seconds } = time;
    const { displayDays, displayHours, displayMinutes, displaySeconds } =
      this.state;

    const daysStr = displayDays ? `${days} days, ` : '';
    const hoursStr = displayHours ? `${hours} hours, ` : '';
    const minutesStr = displayMinutes ? `${minutes} minutes, ` : '';
    const secondsStr = displaySeconds ? `${seconds} seconds` : '';
    const { description } = this.props.cmsData.text.countdown_clock.copy;

    return `${description} ${daysStr}${hoursStr}${minutesStr}${secondsStr}`;
  }

  /**
   *  shouldUpdateAriaAlertMessage()
   *  Determines if the alert message should be updated based on the time remaining.
   *  The message should not update too frequently or the user will be annoyed with alerts.
   * @param time ITime
   * @returns true or false based on whether the message should update or not
   */
  private shouldUpdateAriaAlertMessage(time: ITime): boolean {
    const minutes = parseInt(time.minutes, 10);
    const seconds = parseInt(time.seconds, 10);
    const totalSecondsRemaining = this.getTotalSecondsRemaining();

    // alert message is empty
    if (!this.state.ariaAlertMessage) {
      return true;
    }
    // countdown reached zero
    if (totalSecondsRemaining === 0) {
      return true;
    }
    // every 15 seconds if countdown is under 1 minute remaining
    if (totalSecondsRemaining < MINUTE && seconds % 15 === 0) {
      return true;
    }
    // every minute
    if (minutes >= 0 && seconds % MINUTE === 0) {
      return true;
    }

    return false;
  }
}

export default Connect(Countdown);
