/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EvergentRemoveSubscription = {
    responseCode?: EvergentRemoveSubscription.responseCode;
    message?: string;
    status?: string;
};

export namespace EvergentRemoveSubscription {

    export enum responseCode {
        _1 = '1',
        _0 = '0',
    }


}

