import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';

import { media } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    sponsor_logo_container: {
      padding: '50px 0 35px',
      display: 'flex',
      justifyContent: 'center',
      ...styles.confirmationStyles.sponsorLogoContainer,

      [media.tablet]: {
        ...styles.confirmationStyles.sponsorLogoContainer.tablet,
      },

      [media.desktop]: {
        ...styles.confirmationStyles.sponsorLogoContainer.tablet,
      },
    },

    sponsor_logo_image: {
      maxWidth: '150px',
      ...styles.confirmationStyles.sponsorLogoImage,

      [media.tablet]: {
        ...styles.confirmationStyles.sponsorLogoImage.tablet,
      },

      [media.desktop]: {
        ...styles.confirmationStyles.sponsorLogoImage.tablet,
      },
    },
    media_container: {
      padding: '0px 0px 18px',

      [media.tablet]: {
        padding: '0px',
      },
    },

    information_container: {
      padding: '0 40px 50px',
      ...styles.confirmationStyles.general,

      [media.tablet]: {
        paddingTop: '18px',
      },
    },

    headline: {
      paddingBottom: '0px',
      ...styles.globalStyles.headlines,
      ...styles.confirmationStyles.headline,

      [media.tablet]: {
        fontSize: '24px',
        ...styles.globalStyles.headlines.tablet,
        ...styles.confirmationStyles.headline.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
        ...styles.confirmationStyles.headline.desktop,
      },
    },

    votes: {
      ...styles.globalStyles.headlines,
      ...styles.confirmationStyles.votes,
    },

    description_1: {
      lineHeight: '1.4',
      paddingBottom: '10px',
      ...styles.confirmationStyles.description_1,

      [media.tablet]: {
        paddingBottom: '3px',
      },
    },

    description_2: {
      lineHeight: '1.4',
      paddingBottom: '10px',
      ...styles.confirmationStyles.description_2,

      [media.tablet]: {
        paddingBottom: '3px',
      },
    },

    twitter_options: {
      paddingTop: '15px',
    },

    twitter_headline: {
      ...styles.confirmationStyles.twitter.headline,
    },

    twitter_description: {
      paddingBottom: '15px',
      ...styles.confirmationStyles.twitter.description,
    },
  });
