import { IAppState } from 'models';
import { AppStateProvider } from 'store/index';
import { AuthType, AUTH_METHODS } from 'util/constants';

export function authorizeUser(this: AppStateProvider): void {
  const { userData } = this.state.globalProps;
  userData.isAuthorized = true;

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      userData,
    };
  });
}

export function login(this: AppStateProvider, payload: { email: string; userId: string }): void {
  const { userData } = this.state.globalProps;
  userData.email = payload.email;
  userData.method = AUTH_METHODS.NBA;
  userData.userId = payload.userId;

  this.setState(
    (prevState: IAppState) => {
      return {
        ...prevState,
        userData,
      };
    },
    () => {
      this.state.voteFn.getContestantVotes().then(this.state.authFn.authorizeUser);
    }
  );
}

export function logout(this: AppStateProvider): void {
  const { userData } = this.state.globalProps;
  userData.email = '';
  userData.facebookId = '';
  userData.isAuthorized = false;
  userData.method = '';
  userData.name = '';

  this.state.voteFn.updateVotes();
  this.state.termsFn.updateTerms({});
  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      userData,
    };
  });
}

export function setAuthType(this: AppStateProvider, authType: AuthType): void {
  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      authProps: {
        authType,
      },
    };
  });
}
