import { css } from 'aphrodite/no-important';
import { IAppState } from 'models';

import * as React from 'react';
import { Connect } from 'store/index';
import { style } from './style';

class ErrorFbPermissions extends React.Component<IAppState> {
  render() {
    const { copy, buttons } = this.props.cmsData.text.errors.fb_permissions;
    const { stylesData } = this.props;
    const styles = style({
      fbErrorStyles: stylesData.errors.fbPermissions,
      buttonStyles: stylesData.errors.fbPermissions.buttons.error,
      globalStyles: stylesData.global,
    });

    return (
      <div className={css(styles.fbPermissions)} aria-live='assertive'>
        <h1 className={css(styles.headline)}> {copy.headline} </h1>
        <p className={css(styles.description)}> {copy.description} </p>

        <button
          aria-label='Try again'
          className={css(styles.facebook_button)}
          onClick={() => this.props.loginFn.updateRetryFb(true)}
        >
          {buttons.error.copy}
        </button>
      </div>
    );
  }
}

export default Connect(ErrorFbPermissions);
