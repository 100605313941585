import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';

import * as helpers from 'util/helpers';
import { appMaxWidth, media } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    header: {
      backgroundColor: 'transparent',
      margin: '0 auto',
      maxWidth: helpers.checkIfTrue(styles.settings.displayBleed)
        ? 'none'
        : appMaxWidth,
      minHeight: '175px',
      position: 'relative',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      ...styles.headerStyles.general,

      [media.tablet]: {
        ...styles.headerStyles.general.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.general.desktop,
      },
    },

    ad: {
      marginLeft: 'auto',
      marginTop: '10px',
      width: '45px',

      ...styles.headerStyles.ad,

      [media.tablet]: {
        width: '120px',
        ...styles.headerStyles.ad.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.ad.desktop,
      },
    },

    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '0 auto',
      maxWidth: appMaxWidth,
      width: '100%',
      ...styles.globalStyles.container,
      ...styles.headerStyles.container,

      [media.tablet]: {
        ...styles.globalStyles.container.tablet,
        ...styles.headerStyles.container.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.container.desktop,
        ...styles.headerStyles.container.desktop,
      },
    },

    copy_wrapper: {
      marginTop: 'auto',
      minWidth: '100%',
      padding: '15px 0',
    },

    headline: {
      ...styles.globalStyles.headlines,
      ...styles.headerStyles.headline,
      paddingBottom: '0px',

      [media.tablet]: {
        fontSize: '40px',
        ...styles.globalStyles.headlines.tablet,
      },

      [media.desktop]: {
        ...styles.globalStyles.headlines.desktop,
      },
    },

    description: {
      ...styles.headerStyles.description,

      [media.tablet]: {
        fontSize: '20px',
        ...styles.headerStyles.description.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.description.desktop,
      },
    },

    logo: {
      margin: '15px 10px 0 0',
      ...styles.headerStyles.logo,

      [media.tablet]: {
        ...styles.headerStyles.logo.tablet,
      },

      [media.desktop]: {
        ...styles.headerStyles.logo.desktop,
      },
    },
  });
