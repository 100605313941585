import React, { forwardRef } from 'react';
import { css } from 'aphrodite/no-important';
import { ErrorMessage } from '@hookform/error-message';
import { style } from './style';

interface SelectFieldProps extends React.HTMLProps<HTMLSelectElement> {
  label: string;
  isInvalid?: boolean;
  errors: any;
  hint?: string;
  options: React.HTMLProps<HTMLOptionElement>[];
  name: string;
}
export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({ label, isInvalid, errors, hint, name, options, ...nativeProps }, ref) => {
    const styles = style();
    return (
      <div className={css(styles.form_group)}>
        <label htmlFor={name} className={css(styles.form_label)}>
          {label}
        </label>
        <div className={css(styles.form_select_wrapper)}>
          <select
            ref={ref}
            id={nativeProps.id ?? `input-${name}`}
            className={css(styles.form_input, styles.form_select, isInvalid && styles.form_input_invalid)}
            name={name}
            {...nativeProps}
          >
            {options.map(({ label, ...optionProps }, index) => (
              <option key={index} {...optionProps}>
                {label}
              </option>
            ))}
          </select>
          <div className={css(styles.select_icon_wrapper)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='14'
              width='8'
              viewBox='0 0 8 14'
              data-no-icon='down'
              role='presentation'
              style={{ strokeWidth: 8 }}
            >
              <polyline
                fill='none'
                stroke='currentColor'
                strokeWidth='1'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeMiterlimit='10'
                points='6.5,1.6 1.5,7 6.5,12.4'
              ></polyline>
            </svg>
          </div>
        </div>
        {isInvalid && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <p className={css(styles.form_error)} role='alert' aria-live='assertive'>
                {message}
              </p>
            )}
          />
        )}
      </div>
    );
  }
);
