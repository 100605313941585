import { IAppState } from 'models';
import { AppStateProvider } from 'store/index';

export function setDevice(this: AppStateProvider, device: string): void {
  const { globalProps } = this.state;
  globalProps.device = device;

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      globalProps,
    };
  });
}

export function setTargetIndex(this: AppStateProvider, index: number): void {
  const { globalProps } = this.state;
  globalProps.contestantIndex = index;

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      globalProps,
    };
  });
}

export function detectLanguage(this: AppStateProvider): void {
  const { globalProps } = this.state;
  const supportedLanguages = this.state.cmsData.settings?.languages?.split(',').map((l: string) => l.trim());
  const userLanguage = navigator.language;

  let language = {
    ietf: 'EN-US',
    code: 'en'
  }

  if (supportedLanguages.includes(userLanguage)) {
    language = {
      ietf: userLanguage.toUpperCase(),
      code: userLanguage.split('-')[0].toLowerCase()
    }
  }

  this.setState((prevState: IAppState) => {
    return {
      ...prevState,
      globalProps: {
        ...globalProps,
        language
      },
    };
  });
}

