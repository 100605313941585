/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicSuccess } from '../models/BasicSuccess';
import type { Login } from '../models/Login';
import type { OAuthTokenSuccess } from '../models/OAuthTokenSuccess';
import type { Register } from '../models/Register';
import type { STSToken } from '../models/STSToken';
import type { UseEmailVerificationCodeSuccess } from '../models/UseEmailVerificationCodeSuccess';
import type { ValidateCookie } from '../models/ValidateCookie';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthenticationService {

    /**
     * Login
     * @param xClientPlatform This header determines whether the JWT and refresh token will be sent back in the response payload or a cookie. A value of 'web' will result in the tokens being placed in a cookie. All other values will return the tokens in the response payload.
     * @param requestBody
     * @param xOpinAuthorization This header contains the OPiN JWT. Usage of this header determines whether the user originated from an external partner and triggers linked account information to be saved within CIAM.
     * @returns Login 200 Response - Web
     * @throws ApiError
     */
    public static postAuth(
        xClientPlatform: string,
        requestBody: {
            email: string;
            password: string;
            rememberMe?: boolean;
        },
        xOpinAuthorization?: string,
    ): CancelablePromise<Login> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth',
            headers: {
                'X-Client-Platform': xClientPlatform,
                'X-Opin-Authorization': xOpinAuthorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                403: `403 Forbidden`,
                409: `409 Bad Request`,
                429: `429 Too Many Requests`,
                500: `500 Internal Server Error`,
                503: `503 Service Unavailable`,
            },
        });
    }

    /**
     * @param xClientPlatform This header determines whether the JWT and refresh token will be sent back in the response payload or a cookie. A value of 'web' will result in the tokens being placed in a cookie. All other values will return the tokens in the response payload.
     * @param requestBody
     * @param xOpinAuthorization This header contains the OPiN JWT. Usage of this header determines whether the user originated from an external partner and triggers linked account information to be saved within CIAM.
     * @returns Register 200 response
     * @throws ApiError
     */
    public static postRegister(
        xClientPlatform: string,
        requestBody: {
            emailAddress: string;
            password: string;
            displayName?: string;
            channel: string;
            leagueTeam: string;
            league: string;
            firstName?: string;
            lastName?: string;
            dateOfBirth: string;
            country: string;
            postalCode?: string;
            emailConsent?: string;
            privacyConsent: string;
            privacyConsentTimestamp: string;
            emailConsentTimestamp?: string;
            customSourceDetails?: {
                registerReason?: string;
                registerReasonCode?: string;
                registerSource?: string;
            };
        },
        xOpinAuthorization?: string,
    ): CancelablePromise<Register> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/register',
            headers: {
                'X-Client-Platform': xClientPlatform,
                'X-Opin-Authorization': xOpinAuthorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 Bad Request`,
                403: `403 Forbidden`,
                409: `409 Bad Request`,
                429: `429 Too Many Requests`,
                500: `500 Internal Server Error`,
                503: `503 Service Unavailable`,
            },
        });
    }

    /**
     * @deprecated
     * @param requestBody
     * @returns UseEmailVerificationCodeSuccess 200 OK
     * @throws ApiError
     */
    public static postEmailVerification(
        requestBody: {
            verificationCode: string;
        },
    ): CancelablePromise<UseEmailVerificationCodeSuccess> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/email/verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                403: `403 Forbidden`,
                409: `409 Bad Request`,
                429: `429 Too Many Requests`,
                500: `500 Internal Server Error`,
                503: `503 Service Unavailable`,
            },
        });
    }

    /**
     * @returns BasicSuccess 200 OK
     * @throws ApiError
     */
    public static postLogout(token: string): CancelablePromise<BasicSuccess> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/logout',
            headers: { 
                Authorization: `Bearer ${token}` 
            }
        });
    }

    /**
     * Exchange a refresh token for a new access token
     * @param requestBody
     * @returns OAuthTokenSuccess 200 OK
     * @throws ApiError
     */
    public static postOauthToken(
        requestBody: {
            refreshToken: string;
        },
    ): CancelablePromise<OAuthTokenSuccess> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/oauth/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                403: `403 Forbidden`,
                409: `409 Bad Request`,
                429: `429 Too Many Requests`,
                500: `500 Internal Server Error`,
                503: `503 Service Unavailable`,
            },
        });
    }

    /**
     * Refresh a web client's JWT and refresh token
     * @returns BasicSuccess 200 response
     * @throws ApiError
     */
    public static postWebToken(): CancelablePromise<BasicSuccess> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/token',
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                500: `500 Internal Server Error`,
            },
        });
    }

    /**
     * Return a web client's JWT and refresh token from the cookie
     * @returns ValidateCookie 200 response
     * @throws ApiError
     */
    public static getWebCookieToken(): CancelablePromise<ValidateCookie> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/cookie/token',
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                500: `500 Internal Server Error`,
            },
        });
    }

    /**
     * Return a MediaKind STS Token
     * @returns STSToken 200 response
     * @throws ApiError
     */
    public static getSts(): CancelablePromise<STSToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sts',
            errors: {
                400: `400 Bad Request`,
                401: `401 Unauthorized`,
                500: `500 Internal Server Error`,
            },
        });
    }

}
