import { css } from 'aphrodite/no-important';
import { IAppState } from 'models';

import * as React from 'react';
import { Connect } from 'store/index';
import { IMG_LOADING } from 'util/constants';
import { style } from './style';

class Loading extends React.Component<IAppState> {
  public render(): React.ReactNode {
    const styles = style();

    return (
      <section
        className={css(styles.loading)}
        aria-busy='true'
        aria-live='polite'
      >
        {this.props.loadingProps.title && (
          <h1 className={css(styles.loading_title)}>
            {' '}
            {this.props.loadingProps.title}{' '}
          </h1>
        )}

        <img
          className={css(styles.loading_image)}
          src={IMG_LOADING}
          alt='loading'
        />

        {this.props.loadingProps.description && (
          <p className={css(styles.loading_description)}>
            {' '}
            {this.props.loadingProps.description}{' '}
          </p>
        )}
      </section>
    );
  }
}

export default Connect(Loading);
