/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EvergentAddSubscription = {
    status?: string;
    data?: {
        addSubscriptionResponse?: {
            orderID?: string;
            orderedProductIds?: Array<string>;
            isTaxError?: boolean;
            authCode?: string;
            responseCode?: EvergentAddSubscription.responseCode;
            message?: string;
            validityTill?: string;
        };
        linkAccountResponse?: {
            status?: string;
            notice?: string;
        };
    };
};

export namespace EvergentAddSubscription {

    export enum responseCode {
        _1 = '1',
        _0 = '0',
    }


}

