import React, { forwardRef } from 'react';
import { css } from 'aphrodite/no-important';
import { ErrorMessage } from '@hookform/error-message';

import { style } from './style';

interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  isInvalid?: boolean;
  errors?: any;
  hint?: string;
  name: string;
}
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, isInvalid, errors, hint, name, ...nativeProps }, ref) => {
    const styles = style();
    return (
      <div className={css(styles.form_group)}>
        <label htmlFor={name} className={css(styles.form_label)}>
          {label}
        </label>
        <input
          ref={ref}
          id={nativeProps.id ?? `input-${name}`}
          className={css(styles.form_input, isInvalid && styles.form_input_invalid)}
          name={name}
          {...nativeProps}
        />
        {hint && (
          <p className={css(styles.form_hint)} role='alert' aria-live='assertive'>
            {hint}
          </p>
        )}
        {isInvalid && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <p className={css(styles.form_error)} role='alert' aria-live='assertive'>
                {message}
              </p>
            )}
          />
        )}
      </div>
    );
  }
);
