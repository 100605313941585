import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models';

import * as helpers from 'util/helpers';
import { appMaxWidth, darkenColor, media } from 'util/style-helpers';

export const style: any = (styles: IGenericObject) =>
  StyleSheet.create({
    footer: {
      backgroundColor: '#aaa',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      maxWidth: helpers.checkIfTrue(styles.settings.displayBleed)
        ? 'none'
        : appMaxWidth,
      width: '100%',
      minHeight: '40px',
      ...styles.footerStyles.general,
      backgroundImage: styles.footerStyles.backgroundImage
        ? `url(${styles.footerStyles.backgroundImage})`
        : 'none',

      [media.tablet]: {
        minHeight: '60px',
        ...styles.footerStyles.general.tablet,
      },
    },

    container: {
      display: 'flex',
      alignItems: 'flex-start',
      flexGrow: 1,
      height: '100%',
      maxWidth: appMaxWidth,
      margin: 'auto',
      width: '100%',
      padding: '10px 20px',
      ...styles.footerStyles.general,

      [media.tablet]: {
        paddingLeft: '30px',
        ...styles.footerStyles.general.tablet,
      },

      [media.desktop]: {
        paddingLeft: '12px',
        ...styles.footerStyles.general.desktop,
      },
    },

    footer_link: {
      margin: '0',
      padding: '0 32px 0 0',
      textDecoration: 'none',
      ...styles.footerStyles.link,

      [media.tablet]: {
        fontSize: '12px',
        ...styles.footerStyles.link.tablet,
      },

      [media.desktop]: {
        ...styles.footerStyles.link.desktop,
      },

      ':hover': {
        color: darkenColor(styles.footerStyles.link.color),
      },

      ':focus': {
        color: darkenColor(styles.footerStyles.link.color),
      },
    },
  });
